<template>
  <div class="card p-shadow-6">
    <div class="p-grid p-col-12 p-lg-12">
      <h1>Categorias dos Links Utéis</h1>
      <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
      <Divider />
      <Button
        label="Adicionar Categoria"
        icon="pi pi-plus"
        class="p-button-success p-button-outlined"
        @click="addNew()"
      />

      <Button
          label="Links"
          icon="pi pi-eye"
          class="p-button-success p-button-outlined p-ml-2"
          @click="$router.push(`/useful-links`)"
        />

      <Divider />
      <DataTable
        :value="linksCategories"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        :paginator="true"
        :rows="20"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        currentPageReportTemplate="Mostrar {first} ao {last} de {totalRecords} instalações"
        :sortOrder="1"
        editMode="row"
        dataKey="id"
        responsiveLayout="scroll"
      >
        <template #empty> Nada a mostrar. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <Column field="name" header="Nome" sortable filterField="name">
          <template #body="slotProps">
            <span class="p-column-title">Nome:</span>
            {{ slotProps.data.name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
          </template>
        </Column>
        <Column
          field="active"
          header="Activa"
          sortable
          filterField="active"
          dataType="boolean"
        >
          <template #body="slotProps">
            <span class="p-column-title">Activa:</span>
            {{ slotProps.data.active == true ? "Sim" : "Não" }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @input="filterCallback()"
              :options="[
                { value: false, label: 'Não' },
                { value: true, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>
        <Column :headerStyle="{ width: '50px' }">
          <template #body="slotProps">
            <Button
              v-tooltip.left="'Editar link'"
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success p-button-outlined p-mr-2"
              @click="editLink(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>

      <Dialog
        :visible.sync="showForm"
        :style="{ width: $isMobile() ? '100vw' : '40vw' }"
        :header="
          (linkCategoryToForm.action == 'new' ? 'Criar' : 'Actualizar') + ' Categoria'
        "
        :modal="true"
        :contentStyle="{ overflow: 'visible' }"
      >
        <form
          name="vacationsForm"
          class="p-col-12"
          @submit.prevent="saveNewCompetency"
        >
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-10">
              <span class="p-float-label">
                <InputText
                  type="text"
                  v-validate="'required'"
                  v-model="linkCategoryToForm.name"
                  v-bind:class="[
                    { 'p-invalid': errors.has('categoryName') },
                    'form-control',
                  ]"
                  :id="'categoryName'"
                  :name="'categoryName'"
                />
                <label for="categoryName">Nome da Categoria</label>
              </span>
              <small v-if="errors.has('categoryName')" class="p-error" role="alert">
                Nome da Categoria é obrigatório
              </small>
            </div>

            <div class="p-field p-col-12 p-md-2">
              <span class="p-float-label">
                <Dropdown
                  id="categoryActive"
                  v-model="linkCategoryToForm.active"
                  name="categoryActive"
                  v-validate="'required'"
                  v-bind:class="['form-control']"
                  :inputId="'categoryActive'"
                  :options="[
                    { value: false, label: 'Não' },
                    { value: true, label: 'Sim' },
                  ]"
                  :optionLabel="'label'"
                  :optionValue="'value'"
                />
                <label for="categoryActive">Activa</label>
              </span>
            </div>
          </div>
        </form>

        <template #footer>
          <Button
            :label="
              (linkCategoryToForm.action == 'new' ? 'Criar' : 'Actualizar') + ' Categoria'
            "
            icon="pi pi-check"
            class="p-button-text p-button-success"
            @click="saveForm()"
          />
          <Button
            label="Cancelar"
            icon="pi pi-times"
            class="p-button-text p-button-danger"
            @click="closeForm()"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import linksService from "../services/linksUseful.service";
import { FilterMatchMode } from "primevue/api/";

export default {
  name: "LinksUseful",
  data() {
    return {
      loading: true,
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        active: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      showForm: false,
      linksCategories: [],
      linkCategoryToForm: {},
    };
  },
  async created() {
    await this.getLinksCategories();
    this.loading = false;
  },
  methods: {
    getLinksCategories() {
      return linksService.getAllLinksCategories().then((response) => {
        return (this.linksCategories = response);
      });
    },
    addNew() {
      this.linkCategoryToForm = {
        action: "new",
        active: true,
      };
      this.showForm = true;
    },
    editLink(data) {
      this.linkCategoryToForm = data;
      this.showForm = true;
    },
    getCategoryName(categoryId) {
      let category = this.linksCategories.find((ctg) => ctg.id == categoryId);

      if (category === undefined) {
        return "";
      }

      return category.name;
    },
    closeForm() {
      this.showForm = false;
      this.$validator.pause();
      this.$validator.reset();
      this.linkCategoryToForm = {};
    },
    saveForm() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        if (this.linkCategoryToForm.action === "new") {
          return this.saveNewCategory();
        } else {
          return this.updateCategory();
        }
      });
    },
    saveNewCategory() {
      return linksService.createCategory(this.linkCategoryToForm).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao criar a categoria. Provavelmete o nome já existe",
            life: 3000,
          });
        }
        this.linksCategories.push(response);
        this.closeForm();
        this.$toast.add({
          severity: "success",
          summary: "Categoria criada",
          detail: "A categoria foi criada com sucesso",
          life: 3000,
        });

        return response;
      });
    },
    updateCategory() {
      if (this.linkCategoryToForm.active == true) {
        this.linkCategoryToForm.status = 1;
      } else {
        this.linkCategoryToForm.status = 0;
      }
      return linksService
        .updateCategory(this.linkCategoryToForm.id, this.linkCategoryToForm)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao actualizado a categoria. Verifique se o nome já existe",
              life: 3000,
            });
          }
          this.closeForm();
          this.$toast.add({
            severity: "success",
            summary: "Categoria actualizada",
            detail: "A categoria foi actualizada com sucesso",
            life: 3000,
          });

          return response;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.p-column-filter {
  width: 100%;
}
</style>
